import packageInfo from "../../../../package.json";

export const environment = {
  version: packageInfo.version,
  name: "web.app",
  production: true,
  isWeb: true,
  isDesktop: false,
  baseUrl: "https://skvjgoi22d.execute-api.eu-central-1.amazonaws.com/",
  wsUrl: "wss://3nsaixdaz3.execute-api.eu-central-1.amazonaws.com/app",
  facebookClientId: "1075943315880101",
  googleClientId: "1033597755510-ngc5f3cj4h97d88udn2h0ue1gs71bdf9.apps.googleusercontent.com",
  gaTrackingCode: "G-22C7HCXLGN"
};
